@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,700);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons); */

.calendar {
  border: 0.1rem solid black;
}

.square {
  width: calc(100% / 7);
  padding-bottom: Calc(100% / 7);
  position: relative;
}

.halfway {
  padding-top: 40%;
}

.toggle__dot {
  top: -0.25rem;
  left: -0.25rem;
  transition: all 0.3s ease-in-out;
}

input:checked ~ .toggle__dot {
  transform: translateX(100%);
  background-color: #5cdb95;
}

.page-container {
  position: relative;
  min-height: 100vh;
}

.content-wrap {
  padding-bottom: 2.5rem; /* Footer height */
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2.5rem; /* Footer height */
}
